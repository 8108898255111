import React, { useState } from "react";
import Layout from "../components/layout"
import handleFormSubmit from "../components/mailchimp"
import SEO from "../components/seo"
import { resetMenu } from "../components/menu"
import { setGradient } from "../components/gradient"

const IndexPage = ({ location }) => {
  const [isValidationDone, setIsValidationDone] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const emailAddress = location.state?.emailAddress || null;

  resetMenu();
  setGradient({
    fromColor: 'rgb(245, 255, 250)',
    toColor: 'rgb(207 218 218)'
  })

  const handleSubmit = async e => {
    const form = document.querySelector('.signup-form');

    if (!form.checkValidity()) {
      return;
    }

    e && e.preventDefault();

    document.querySelector('[name=js-audyem-submit]').classList.add('inactive');

    const message = await handleFormSubmit(e);

    if (message.result === 'error' && message.msg.indexOf('already subscribed') > -1) {
      setValidationMessage('We have already received your request.');
      return;
    }

    if (message.result === 'error' && message.msg.indexOf('enter a different ') > -1) {
      setValidationMessage('Invalid email address.');
      return;
    }

    if (message.result === 'success') {
      setValidationMessage('Thanks.');
    }
    
    setIsValidationDone(true);
  };

  return (
    <Layout>
      <SEO title="Request Trial" description="Reach out to Audyem and book a free product demo or trial today."/>
      <div className="max-w-3xl pt-6 md:pt-12 mx-auto relative z-10">
        <div className="max-w-xl mx-4">
          <h1 className="mb-8 font-semibold text-black tracking-tight">
            Try Audyem now
          </h1>
          <p className="max-w-lg text-lg text-gray-800">
            No credit cards, no upfront costs. We're also throwing in one month of support and answer any questions you might have.
            Always human, no bots involved.
          </p>
          { !isValidationDone &&
            <form className="signup-form mt-8" action="https://mc.us16.list-manage.com/subscribe/post-json?u=5f0d4c3753829f314f18a6314&id=0b96174867" method="GET">
              <input required className="w-64 mb-4 text-sm outline-none px-3 py-2 b-4 focus rounded-lg mr-4 placeholder-gray-600" autoFocus placeholder="Your Name"></input>
              <input defaultValue={emailAddress} required type="email" className="w-64 mb-4 text-sm outline-none px-3 py-2 b-4 focus rounded-lg placeholder-gray-600" placeholder="your@email.com"></input>
              <select required className="bg-white w-64 mb-2 text-sm outline-none block px-3 py-2 b-4 focus rounded-lg appearance-none" defaultValue="">
                <option value="" disabled>Company size</option>
                <option value="1">&lt; 50</option>
                <option value="2">51 - 100</option>
                <option value="3">101 - 200</option>
                <option value="4">201 - 500</option>
                <option value="5">1000+</option>
              </select>
              <button onClick={handleSubmit} name="js-audyem-submit" className="block text-sm mt-8 px-10 py-2 flex-shrink-0 transition duration-200 ease-in-out bg-black hover:bg-gray-900 text-white rounded-full inline cursor-pointer outline-none font-bold audyem-nudge" type="submit">
                Request your trial now <span className="audyem-nudge-elem pr-2">→</span>
              </button>
              <span className="text-xs text-gray-700 pl-4">{ validationMessage }</span>
            </form>
          }
          { isValidationDone &&
            <p className="text-sm max-w-lg mt-8 text-green-900">
              Thanks for reaching out!
            </p>
          }
        </div>
      </div>

      <div className="max-w-100 w-screen absolute audyem-bg-wave -mt-32 pt-32 pb-24"></div>

      <div className="w-full mt-20 pt-2 bg-white pb-4">
        <div className="max-w-3xl mx-auto relative z-10 mt-8 mb-0">
        
          <table className="text-left rounded-lg mx-4" style={{boxShadow: 'inset 5px 6px 5px rgb(1 1 1 / 2%)', fontSize: '.85rem'}}>
            <tbody className="align-top">
              <tr className="border-b-6 border-white">
                <td className="font-bold text-gray-800 pt-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">✅</span>&nbsp; No upfront costs</td>
                <td className="pr-4 py-2 text-gray-800 pt-3">We will not charge you until you're convinced.</td>
              </tr>
              <tr className="border-b-6 border-white">
                <td className="font-bold text-gray-800 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">✅</span>&nbsp; GDPR compliancy</td>
                <td className="pr-4 py-2 text-gray-800">Our customers are data-sensitive and so are we. Audyem does not make use of cookies at all. All personally identifiable data (PII) can be fully anonymized. Audyem is compliant with EU privacy law.</td>
              </tr>
              <tr className="border-white">
                <td className="font-bold text-gray-800 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">✅</span>&nbsp; System integration</td>
                <td className="pr-4 py-2 text-gray-800">Audyem can work with any CRM imaginable through either authenticated webhooks, custom API integration, or even enterprise event interfaces like Kafka Connect. </td>
              </tr>
              <tr className="border-white">
                <td className="font-bold text-gray-800 pb-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">✅</span>&nbsp; Paperwork</td>
                <td className="pr-4 py-2 text-gray-800 pb-3">Among our customers are some true publishing heavy-weights. We have prepared all the legal paperwork in accordance with them. From SaaS to data and privacy contracts. It's all there and approved by Audyem customers just like you.</td>
              </tr>
              <tr className="border-white">
                <td className="font-bold text-gray-800 pb-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">✅</span>&nbsp; Pricing</td>
                <td className="pr-4 py-2 text-gray-800 pb-3">Our pricing depends on your scale, usage and integration work. We'll give you an individual quote ASAP.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="w-screen bg-white">
        <div className="w-screen pt-8 pb-8 mx-auto relative z-10 text-center">
          <div className="max-w-2xl px-2 mx-auto z-0 pt-0 mt-0 flex flex-wrap md:flex-no-wrap justify-center">
            <div className="audyem-partners audyem-partners--1"></div>
            <div className="audyem-partners audyem-partners--2"></div>
            <div className="audyem-partners audyem-partners--3"></div>
            <div className="audyem-partners audyem-partners--4"></div>
            <div className="audyem-partners audyem-partners--5"></div>
            <div className="audyem-partners audyem-partners--6"></div>
            <div className="audyem-partners audyem-partners--7"></div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default IndexPage
